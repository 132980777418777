import { Spinner } from "reactstrap";

// ** Logo
// import logo from "@src/assets/images/logo/lsp.png";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <div className="loading">
        {/* <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div> */}
        <Spinner
          color="primary"
          style={{ width: "3.8rem", height: "3.8rem" }}
        />
      </div>
    </div>
  );
};

export default SpinnerComponent;
