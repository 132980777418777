// **  Initial State
const initialState = {
  formData: {},
  loading: false,
  status: "",
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_FORM_DATA":
      return { ...state, formData: action.data };
    case "LOADING":
      return { ...state, loading: action.data };
    case "STATUS":
      return { ...state, status: action.data };
    default:
      return state;
  }
};

export default formReducer;
