// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import form from "./form";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  form,
});

export default rootReducer;
